<template>
  <v-dialog
    scrollable
    :width="width ? width : '800'"
    :persistent="persistent ? persistent : true"
    v-model="dialog"
  >
    <v-card elevation="0">
      <v-toolbar dense flat height="65px">
        <v-toolbar-title>
          <div class="d-flex py-3 flex-column justify-content-start">
            <h3 class="title-dialog mt-2 mb-0">{{ title }}</h3>
            <p class="mb-0 subtitle-dialog">
              <small class="">{{ subtitle }}</small>
            </p>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon small @click=" () => { $store.dispatch('clearResponseMessage'); closeDialog() }">
          <v-icon size="22">{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="scrolled scroll-type-2" style="max-height: 80vh">
        <slot name="form-dialog"> </slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js';
export default {
  props: {
    width: String,
    dialog: Boolean,
    persistent: Boolean,
    title: String,
    subtitle: String,
    closeDialog: Function,
  },
  setup() {
      return {
          icons: {
              mdiClose
          }
      }
  }
};
</script>

<style lang="scss" scoped>
.justify-content-start {
    justify-content: flex-start;
}
.flex-column {
    flex-direction: column;
}
.title-dialog {
    font-size: 17.5px;
}

.subtitle-dialog {
    font-size: 14.5px;
}
</style>
