<template>
  <v-card elevation="0">
    <HeaderView title="Cards" @openToAdd="openDialog" :noSearch="true"></HeaderView>
    <ListDenunciations :denunciations="denunciations" @edit="openEdit" @remove="openRemove" />
    <WDialog
      width="500"
      :dialog="dialog"
      :closeDialog="closeDialog"
      :title="titleDialog"
      :subtitle="subtitleDialog"
    >
      <template v-slot:form-dialog>
        <FormDenunciations
          v-if="dialog"
          :loading="loading"
          :payloadEdited="denunciationsEdited"
          @beforeUpdate="updateDenunciations"
          @beforeCreate="createDenunciations">
        </FormDenunciations>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import FormDenunciations from "@/components/forms/FormDenunciations.vue";
import ListDenunciations from "./ListDenunciations.vue";
import HeaderView from "@/components/HeaderView.vue";
import WDialog from "@/components/dialogs/WDialog.vue";
import useDenunciations from "@/composables/useDenunciations";

export default {
  components: {
    FormDenunciations,
    HeaderView,
    ListDenunciations,
    WDialog,
  },
  setup() {
    // Composition API
    const {
      dialog,
      denunciationsEdited,
      denunciations,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      closeDialog,
      // crud
      getDenunciationsList,
      createDenunciations,
      updateDenunciations,
      removeDenunciations,
      openRemove,
    } = useDenunciations();

    return {
      dialog,
      denunciationsEdited,
      denunciations,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      closeDialog,
      // crud
      getDenunciationsList,
      createDenunciations,
      updateDenunciations,
      removeDenunciations,
      openRemove
    };
  },
};
</script>

<style lang="scss" scoped></style>
