import { ref } from '@vue/composition-api';
import store from '@/store/index';

const useDenunciationsPg = () => {
  // base data
  const denunciationsPg = ref(null);
  const loading = ref(false);

  // methods
  const getDenunciationsPg = async () => {
    loading.value = true;
    const { data, status } = await store.dispatch('fetchDenunciationsPg');
    loading.value = false;
    if (status != 200) return;
    denunciationsPg.value = data;
    return data;
  };

  const updateDenunciationsPg = async denunciationsPg => {
    loading.value = true;
    const { data, status } = await store.dispatch('updateDenunciationsPg', denunciationsPg);
    loading.value = false;
    if (status != 200 && status != 201) return;
    return data;
  };

  return {
    denunciationsPg,
    loading,
    // methods
    getDenunciationsPg,
    updateDenunciationsPg,
  };
};

export default useDenunciationsPg;
