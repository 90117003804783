<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formDenunciationsInformations" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="2">
            <label for="title">Título de la sección</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="title"
              v-model="denunciationsInformations.title"
              outlined
              dense
              placeholder="Título de la Sección"
              :rules="required"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <label for="content">Contenido</label>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-textarea
              id="content"
              v-model="denunciationsInformations.content"
              outlined
              dense
              placeholder="Cargo del Funcionario"
              rows="5"
              :rules="required"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="2">
            <label for="file">Icono lateral derecho</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="file"
              v-model="file"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileChange"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="6">
            <div class="icon-cover section">
              <img
                draggable="false"
                :src="url || loadImage(denunciationsInformations.icon)"
                :alt="denunciationsInformations.text"
              />
            </div>
          </v-col>

          <v-col cols="12" md="2">
            <label for="file_formulario">Icono formulario</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="file_formulario"
              v-model="file_formulario"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileNewChange"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="6">
            <div class="icon-cover section">
              <img
                draggable="false"
                :src="urlNew || loadImage(denunciationsInformations.icon_formulario)"
                :alt="denunciationsInformations.text"
              />
            </div>
          </v-col>
          <v-col cols="12" md="2">
            <label for="title_formulario">Titulo:</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="title_formulario"
              v-model="denunciationsInformations.title_formulario"
              outlined
              dense
              placeholder="Título de la Sección"
              :rules="required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <label for="text_formulario">Texto</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="text_formulario"
              v-model="denunciationsInformations.text_formulario"
              outlined
              dense
              placeholder="Título de la Sección"
              :rules="required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <label for="url_formulario">Url</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="url_formulario"
              v-model="denunciationsInformations.url_formulario"
              outlined
              dense
              placeholder="www.google.com"
              :rules="required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <label></label>
          </v-col>
          <v-col cols="12" md="2">
            <label for="file_cell">Icono Telefono</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="file_cell"
              v-model="file_cell"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileOtherChange"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="6">
            <div class="icon-cover section">
              <img
                draggable="false"
                :src="newUrl || loadImage(denunciationsInformations.icon_cell)"
                :alt="denunciationsInformations.text"
              />
            </div>
          </v-col>
          <v-col cols="12" md="2">
            <label for="title_cell">Titulo</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="title_cell"
              v-model="denunciationsInformations.title_cell"
              outlined
              dense
              placeholder="Título de la Sección"
              :rules="required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <label for="text_cell">Texto</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="text_cell"
              v-model="denunciationsInformations.text_cell"
              outlined
              dense
              placeholder="Título de la Sección"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="file_email">Icono de correo</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="file_email"
              v-model="file_email"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileNewOtherChange"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="6">
            <div class="icon-cover section">
              <img
                draggable="false"
                :src="otherUrl || loadImage(denunciationsInformations.icon_email)"
                :alt="denunciationsInformations.text"
              />
            </div>
          </v-col>
          <v-col cols="12" md="2">
            <label for="title_email">Apartado</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="title_email"
              v-model="denunciationsInformations.title_email"
              outlined
              dense
              placeholder="Título de la Sección"
              :rules="required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <label for="text_email">Texto</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="text_email"
              v-model="denunciationsInformations.text_email"
              outlined
              dense
              placeholder="Título de la Sección"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import DenunciationsQuestions from '@/views/pages/denunciations-questions/DenunciationsQuestions.vue'
import Denunciations from '@/views/pages/denunciations/Denunciations.vue'
import DenunciationsInformations from '@/views/pages/denunciations-informations/DenunciationsInformations.vue'
import { loadAPIImage } from '@/utils/images'
export default {
  components: {
    FormTemplate,
    DenunciationsQuestions,
    Denunciations,
    DenunciationsInformations,
  },
  props: {
    getDenunciationsInformations: Function,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      urlNew: '',
      newUrl: '',
      otherUrl: '',
      file: null,
      file_formulario: null,
      file_cell: null,
      file_email: null,
      denunciationsInformations: {},
    }
  },
  async created() {
    this.denunciationsInformations = await this.getDenunciationsInformations()
  },
  methods: {
    validateForm() {
      return this.$refs.formDenunciationsInformations.validate()
    },
    reset() {
      this.$refs.formDenunciationsInformations.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      let DTO = { ...this.denunciationsInformations }

      if (this.file) {
        DTO = {
          ...DTO,
          file: this.file,
        }
      }

      if (this.file_formulario) {
        DTO = {
          ...DTO,
          file_formulario: this.file_formulario,
        }
      }

      if (this.file_cell) {
        DTO = {
          ...DTO,
          file_cell: this.file_cell,
        }
      }

      if (this.file_email) {
        DTO = {
          ...DTO,
          file_email: this.file_email,
        }
      }

      // // console.log('actualizar', DTO)
      this.$emit('beforeUpdate', DTO)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
    onFileNewChange(e) {
      if (!e) return
      this.urlNew = URL.createObjectURL(e)
    },
    onFileOtherChange(e) {
      if (!e) return
      this.newUrl = URL.createObjectURL(e)
    },
    onFileNewOtherChange(e) {
      if (!e) return
      this.otherUrl = URL.createObjectURL(e)
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-cover.section {
  display: flex;
  justify-content: center;
}
.icon-cover img {
  width: 150px;
  height: 130px;
}

.img-cover {
  height: 30vh;
  max-height: 400px !important;
  min-height: 250px !important;
  position: relative;
}

.img-cover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.img-cover .cover-title {
  width: 100%;
  position: absolute;
  bottom: 10%;
  color: white !important;
  font-size: 25px;
  margin-left: 10%;
  text-transform: uppercase;
}

.img-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.img-about {
  width: 100%;
}

.title-about {
  font-size: 25px;
  font-weight: 800;
  text-transform: uppercase;
}

.content-about {
  display: flex;
  align-items: center;
}

.text-about {
  white-space: pre-wrap;
  font-size: 14px;
}

.section {
  margin: 0 auto;
  max-width: 900px;
}
</style>
