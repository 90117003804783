<template>
  <v-card class="bs-none">
    <DenunciationsInformationsHeader></DenunciationsInformationsHeader>
    <section class="form-full-width">
      <FormDenunciationsInformations :loading="loading" :getDenunciationsInformations="getDenunciationsInformations" @beforeUpdate="updateDenunciationsInformations"></FormDenunciationsInformations>
    </section>
  </v-card>
</template>

<script>
import FormDenunciationsInformations from '@/components/forms/FormDenunciationsInformations.vue'
import DenunciationsInformationsHeader from './DenunciationsInformationsHeader.vue'
import useDenunciationsInformations from '@/composables/useDenunciationsInformations'

export default {
  components: {
    FormDenunciationsInformations,
    DenunciationsInformationsHeader,
  },
  setup() {
    // Composition API
    const {
      denunciationsInformations,
      loading,
      // methods
      getDenunciationsInformations,
      updateDenunciationsInformations,
    } = useDenunciationsInformations()

    return {
      denunciationsInformations,
      loading,
      // methods
      getDenunciationsInformations,
      updateDenunciationsInformations,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
.bs-none {
  box-shadow: none !important;
}
</style>
