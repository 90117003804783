<template>
  <v-card class="card-reel bg-gray">
    <v-img height="250px" :src="loadAPIImage(item.icon_card)" class="img-reel">
      <div class="actions-card">
        <v-spacer></v-spacer>
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn elevation="1" v-bind="attrs" v-on="on" icon color="primary">
              <v-icon color="#fafafa" size="20">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list class="z-index-100">
            <v-list-item link @click="$emit('edit', item)">
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$emit('remove', item)">
              <v-list-item-title>Eliminar</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-img>
    <div :class="`releases-details`">
      <a :href="loadAPIImage(item.upload)" class="text-decoration-none" target="_blank">
        <h3 class="title-releases">
          {{ item.title_card }}
        </h3>
      </a>
    </div>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import { loadAPIImage } from '@/utils/images'
export default {
  props: {
    item: Object,
    srcImage: String,
  },
  setup() {
    return {
      icons: {
        mdiDotsVertical,
      },
      loadAPIImage,
    }
  },
}
</script>

<style lang="scss" scoped>
.card-reel {
  .actions-card {
    position: absolute;
    right: 0;
    top: 0;
  }
  .container-slide {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 80%;
    transform: translate(-50%, -50%);
    .notice-details {
      margin: 15px 30px 20px !important;
    }
    @media screen and (max-width: 550px) {
      max-width: 90%;
    }
  }
  .releases-details {
    position: absolute;
    top: 150px;
    left: 0px;
    text-align: center;
    background: #484242;
    background-color: rgb(72, 66, 66);
    opacity: 0.5;
    color: black !important;
    font-weight: normal;
    font-size: 17px;
    background-color: #fff !important;
    border-radius: 0.25rem !important;
    padding: 0.5rem !important;
    margin-bottom: 1rem !important;
    width: 100% !important;

    .title-releases {
      font-size: 18px;
    }
    .description-releases {
      font-size: 12px;
      overflow-wrap: break-word;
      font-weight: 500;
    }
    .date-releases {
      font-size: 12px;
      color: var(--text-gray-2);
      display: flex;
      justify-content: flex-end;
    }
    .btn-container-releases {
      display: flex;
      justify-content: flex-end;
      .btn-releases {
        text-decoration: none;
        font-family: 'Seravek-Bold', 'Segoe UI';
        background: #e83762;
        color: white;
        display: inline-flex;
        align-items: center;
        padding: 7px;
        gap: 5px;
        margin: 20px 0 10px 0;
      }
    }
  }
}
.card-reel.bg-gray {
  background: gray !important;
}
.img-reel {
  object-fit: cover !important;
  object-position: center !important;
}
.img-reel::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
</style>
