import { render, staticRenderFns } from "./ListDenunciations.vue?vue&type=template&id=0a1894d7&scoped=true&"
import script from "./ListDenunciations.vue?vue&type=script&lang=js&"
export * from "./ListDenunciations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a1894d7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardText,VCol,VDataIterator,VRow})
