<template>
  <v-card>
    <DenunciationsPgHeader></DenunciationsPgHeader>
    <section class="form-full-width">
      <FormDenunciationsPg :loading="loading" :getDenunciationsPg="getDenunciationsPg" @beforeUpdate="updateDenunciationsPg"></FormDenunciationsPg>
    </section>
  </v-card>
</template>

<script>
import FormDenunciationsPg from '@/components/forms/FormDenunciationsPg.vue'
import DenunciationsPgHeader from './DenunciationsPgHeader.vue'
import useDenunciationsPg from '@/composables/useDenunciationsPg'

export default {
  components: {
    FormDenunciationsPg,
    DenunciationsPgHeader,
  },
  setup() {
    // Composition API
    const {
      denunciationsPg,
      loading,
      // methods
      getDenunciationsPg,
      updateDenunciationsPg,
    } = useDenunciationsPg()

    return {
      denunciationsPg,
      loading,
      // methods
      getDenunciationsPg,
      updateDenunciationsPg,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
