<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formDenunciationsPg" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12">
            <h3>Portada</h3>
          </v-col>
          <v-col cols="12" md="2">
            <label for="title_cover_page">Título de Portada</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="title_cover_page"
              v-model="denunciationsPg.title_cover_page"
              outlined
              dense
              placeholder="Título de la Portada"
              :rules="required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <label for="img_cover_page">Imagen de Portada</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="img_cover_page"
              v-model="file_cover_page"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileChange"
            ></v-file-input>
          </v-col>
          <v-col cols="12">
            <div class="img-cover section">
              <img
                draggable="false"
                :src="url || loadImage(denunciationsPg.img_cover_page)"
                :alt="denunciationsPg.text"
              />
              <h1 class="cover-title">{{ denunciationsPg.title_cover_page }}</h1>
            </div>
          </v-col>
          <v-col cols="12">
            <h3>Card ISO</h3>
          </v-col>
          <v-col cols="12" md="2">
            <label for="title">Título de la sección</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="title"
              v-model="denunciationsPg.title"
              outlined
              dense
              placeholder="Título"
              :rules="required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <label for="title_card">Título del card</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="title_card"
              v-model="denunciationsPg.title_card"
              outlined
              dense
              placeholder="Título"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="icon_card">Imagen del card</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="icon_card"
              v-model="file_card"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileNewChange"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="6">
            <div class="icon-cover section">
              <img
                draggable="false"
                :src="urlNew || loadImage(denunciationsPg.icon_card)"
                :alt="denunciationsPg.text"
                class="logo-card"
              />
            </div>
          </v-col>

          <v-col cols="12" md="2">
            <label for="content_card">Contenido del card</label>
          </v-col>

          <v-col cols="12" md="10" class="pb-0">
            <v-textarea
              id="content_card"
              v-model="denunciationsPg.content_card"
              outlined
              dense
              placeholder="Cargo del Funcionario"
              rows="5"
              :rules="required"
            ></v-textarea>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
          <v-col cols="12">
            <hr class="color-op" />
          </v-col>

          <v-col cols="12">
            <Denunciations></Denunciations>
          </v-col>
          <v-col cols="12">
            <hr class="color-op" />
          </v-col>

          <v-col cols="12">
            <DenunciationsQuestions></DenunciationsQuestions>
          </v-col>
          <v-col cols="12">
            <hr class="color-op" />
          </v-col>
          <v-col cols="12">
            <DenunciationsInformations></DenunciationsInformations>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import DenunciationsQuestions from '@/views/pages/denunciations-questions/DenunciationsQuestions.vue'
import Denunciations from '@/views/pages/denunciations/Denunciations.vue'
import DenunciationsInformations from '@/views/pages/denunciations-informations/DenunciationsInformations.vue'
import { loadAPIImage } from '@/utils/images'
export default {
  components: {
    FormTemplate,
    DenunciationsQuestions,
    Denunciations,
    DenunciationsInformations,
  },
  props: {
    getDenunciationsPg: Function,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      urlNew: '',
      file_cover_page: null,
      file_card: null,
      denunciationsPg: {},
    }
  },
  async created() {
    this.denunciationsPg = await this.getDenunciationsPg()
  },
  methods: {
    validateForm() {
      return this.$refs.formDenunciationsPg.validate()
    },
    reset() {
      this.$refs.formDenunciationsPg.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      let DTO = { ...this.denunciationsPg }

      if (this.file_cover_page) {
        DTO = {
          ...DTO,
          file_cover_page: this.file_cover_page,
        }
      }

      if (this.file_card) {
        DTO = {
          ...DTO,
          file_card: this.file_card,
        }
      }

      // // console.log('actualizar', DTO)
      this.$emit('beforeUpdate', DTO)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
    onFileNewChange(e) {
      if (!e) return
      this.urlNew = URL.createObjectURL(e)
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
  },
}
</script>

<style lang="scss" scoped>
.color-op {
  color: #cccccc !important;
}
.icon-cover.section {
  display: flex;
  justify-content: center;
}
.icon-cover img {
  width: 50%;
  height: 50%;
}
.img-cover {
  height: 30vh;
  max-height: 400px !important;
  min-height: 250px !important;
  position: relative;
}

.img-cover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.logo-card {
  width: 150px !important;
  height: 50%;
}

.img-cover .cover-title {
  width: 100%;
  position: absolute;
  bottom: 10%;
  color: white !important;
  font-size: 25px;
  margin-left: 10%;
  text-transform: uppercase;
}

.img-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.img-about {
  width: 100%;
}

.title-about {
  font-size: 25px;
  font-weight: 800;
  text-transform: uppercase;
}

.content-about {
  display: flex;
  align-items: center;
}

.text-about {
  white-space: pre-wrap;
  font-size: 14px;
}

.section {
  margin: 0 auto;
  max-width: 900px;
}
</style>
