import { ref, computed } from '@vue/composition-api';
import store from '@/store/index';

const useDenunciations = () => {
  // base data
  const dialog = ref(false);
  const denunciationsEdited = ref(null);
  const denunciations = ref([]);
  const loading = ref(false);

  // computed properties
  const titleDialog = computed(
    () =>
      denunciationsEdited.value
        ? 'Editar Card'
        : 'Agregar Card'
  );
  const subtitleDialog = computed(
    () =>
      denunciationsEdited.value
        ? 'Rellene los campos correctamente para modificar los datos del card'
        : 'Rellene los campos correctamente para agregar un nuevo card'
  );

  // methods
  const openDialog = () => {
    dialog.value = true;
  };
  const openEdit = denunciations => {
    dialog.value = true;
    denunciationsEdited.value = { ...denunciations };
  };
  const closeDialog = () => {
    dialog.value = false;
    denunciationsEdited.value = null;
  };

  // crud
  const getDenunciationsList = async () => {
    const { data, status } = await store.dispatch('fetchDenunciations');
    if (status != 200) return;
    denunciations.value = data;
  };

  const createDenunciations = async denunciations => {
    loading.value = true;
    const { data, status } = await store.dispatch(
      'createDenunciations',
      denunciations
    );
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getDenunciationsList();
    closeDialog();
  };

  const updateDenunciations = async denunciations => {
    loading.value = true;
    const { data, status } = await store.dispatch(
      'updateDenunciations',
      denunciations
    );
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getDenunciationsList();
    closeDialog();
  };

  const removeDenunciations = async user => {
    const val = await store.dispatch('generateConfirmDialog', {});
    if (!val) return;
    const { data, status } = await store.dispatch('removeDenunciations', user);
    if (status != 200 && status != 201 && status != 204) return;
    await getDenunciationsList();
  };

  getDenunciationsList();

  return {
    dialog,
    denunciationsEdited,
    denunciations,
    loading,
    //crud
    createDenunciations,
    updateDenunciations,
    openRemove: removeDenunciations,
    // methods
    openDialog,
    openEdit,
    closeDialog,
    // computed
    titleDialog,
    subtitleDialog,
  };
};

export default useDenunciations;
