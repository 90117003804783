import { ref } from '@vue/composition-api';
import store from '@/store/index';

const useDenunciationsQuestions = () => {
  // base data
  const denunciationsQuestions = ref(null);
  const loading = ref(false);

  // methods
  const getDenunciationsQuestions = async () => {
    loading.value = true;
    const { data, status } = await store.dispatch('fetchDenunciationsQuestions');
    loading.value = false;
    if (status != 200) return;
    denunciationsQuestions.value = data;
    return data;
  };

  const updateDenunciationsQuestions = async denunciationsQuestions => {
    loading.value = true;
    const { data, status } = await store.dispatch('updateDenunciationsQuestions', denunciationsQuestions);
    loading.value = false;
    if (status != 200 && status != 201) return;
    return data;
  };

  return {
    denunciationsQuestions,
    loading,
    // methods
    getDenunciationsQuestions,
    updateDenunciationsQuestions,
  };
};

export default useDenunciationsQuestions;
