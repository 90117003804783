<template>
  <v-card class="bs-none">
    <DenunciationsQuestionsHeader></DenunciationsQuestionsHeader>
    <section class="form-full-width">
      <FormDenunciationsQuestions
        :loading="loading"
        :getDenunciationsQuestions="getDenunciationsQuestions"
        @beforeUpdate="updateDenunciationsQuestions"
      ></FormDenunciationsQuestions>
    </section>
  </v-card>
</template>

<script>
import FormDenunciationsQuestions from '@/components/forms/FormDenunciationsQuestions.vue'
import DenunciationsQuestionsHeader from './DenunciationsQuestionsHeader.vue'
import useDenunciationsQuestions from '@/composables/useDenunciationsQuestions'

export default {
  components: {
    FormDenunciationsQuestions,
    DenunciationsQuestionsHeader,
  },
  setup() {
    // Composition API
    const {
      denunciationsQuestions,
      loading,
      // methods
      getDenunciationsQuestions,
      updateDenunciationsQuestions,
    } = useDenunciationsQuestions()

    return {
      denunciationsQuestions,
      loading,
      // methods
      getDenunciationsQuestions,
      updateDenunciationsQuestions,
    }
  },
}
</script>

<style lang="scss" scoped>
.bs-none {
  box-shadow: none !important;
}
.form-full-width {
  padding: 10px;
}
</style>
