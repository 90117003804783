<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formDenunciations" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="3">
            <label for="title_card">Titulo del card</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="title_card"
              v-model="payload['title_card']"
              outlined
              dense
              :autocomplete="autocomplete"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="file_card">Icono</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="file_card"
              v-model="images"
              outlined
              dense
              placeholder="Selecciona una imagen"
              :autocomplete="autocomplete"
              :rules="payloadEdited ? [] : required"
              chips
            ></v-file-input>
          </v-col>

          <v-col cols="12" md="3">
            <label for="color">Archivo de carga</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="color"
              v-model="upload"
              outlined
              dense
              placeholder="Selecciona un archivo"
              :autocomplete="autocomplete"
              :rules="payloadEdited ? [] : required"
              chips
            ></v-file-input>
          </v-col>

          <v-col cols="12" md="3">
            <label for="color">Elige un color</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-select
              id="color"
              v-model="payload['color']"
              outlined
              dense
              placeholder="Elige un color"
              :rules="required"
              persistent-hint
              single-line
              item-text="color"
              item-value="class"
              :hint="payload['color']"
              :items="items"
            ></v-select>
          </v-col>
          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>
<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
export default {
  components: {
    FormTemplate,
  },
  props: {
    payloadEdited: Object,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      autocomplete: 'off',
      isEdited: this.payloadEdited ? true : false,
      images: null,
      upload: null,
      payload: this.payloadEdited ? { ...this.payloadEdited } : { color: '' },
      select: { color: 'azul', class: 'bg-blue' },
      items: [
        { color: 'azul', class: 'bg-blue' },
        { color: 'turquesa', class: 'bg-turquise' },
        { color: 'rosado', class: 'bg-pink' },
        { color: 'amarillo', class: 'bg-yellow' },
      ],
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formDenunciations.validate()
    },
    reset() {
      this.payload['title_denunciations'] = ''
      this.payload['title_card'] = ''
      this.payload['color'] = ''
      ;(this.images = null), (this.upload = null)
      this.$refs.formDenunciations.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      const formData = new FormData()
      formData.append('title_denunciations', this.payload['title_denunciations'])
      formData.append('title_card', this.payload['title_card'])
      formData.append('color', this.payload['color'])
      if (this.images) {
        formData.append('file_card', this.images)
      }
      if (this.upload) {
        formData.append('file_upload', this.upload)
      }
      const DTO = {
        id: this.payload.id,
        formData,
      }

      // // console.log(DTO)
      if (this.isEdited) {
        this.$emit('beforeUpdate', DTO)
      } else {
        this.$emit('beforeCreate', DTO)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
