import { ref } from '@vue/composition-api';
import store from '@/store/index';

const useDenunciationsInformations = () => {
  // base data
  const denunciationsInformations = ref(null);
  const loading = ref(false);

  // methods
  const getDenunciationsInformations = async () => {
    loading.value = true;
    const { data, status } = await store.dispatch('fetchDenunciationsInformations');
    loading.value = false;
    if (status != 200) return;
    denunciationsInformations.value = data;
    return data;
  };

  const updateDenunciationsInformations = async denunciationsInformations => {
    loading.value = true;
    const { data, status } = await store.dispatch('updateDenunciationsInformations', denunciationsInformations);
    loading.value = false;
    if (status != 200 && status != 201) return;
    return data;
  };

  return {
    denunciationsInformations,
    loading,
    // methods
    getDenunciationsInformations,
    updateDenunciationsInformations,
  };
};

export default useDenunciationsInformations;
